import React, { useState } from "react"
import axios from "axios"

import styled from "styled-components"
import theme from "../../../styles/theme"

import Reaptcha from "reaptcha"

const ContactFormWrapper = styled.div`
  width: 100%;
`

const InformationParagraph = styled.p`
  color: ${props => props.color};
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
  text-align: center;
`

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;

  label {
    line-height: 23px;
    font-size: 13px;
    margin-bottom: 2px;
    color: #626262;
  }
`

const StyledField = styled.input`
  margin-bottom: 20px;
  padding: 5px;
  border: none;
  border-radius: 5px;
`

export const StyledSubmit = styled.button`
  width: fit-content;
  padding: 8px 15px;
  margin: 0 auto;
  letter-spacing: 2px;
  margin-top: 40px;
  background-color: white;
  font-weight: bold;
  font-size: 12px;
  border: 1px solid black;

  &:hover {
    cursor: pointer;
  }
`

const ReaptchaMargin = styled.div`
  height: 30px;
  width: 100%;
  background-color: transparent;
`

const ContactForm = () => {
  const [formState, setFormState] = useState({
    email: "",
    firstname: "",
    message: "",
    buttonEnabled: false,
  })

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  })

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    })

    if (ok) {
      setFormState({
        email: "",
        firstname: "",
        message: "",
        buttonEnabled: false,
      })

      form.reset()
    }
  }

  const handleOnSubmit = e => {
    e.preventDefault()
    const form = e.target
    setServerState({ submitting: true })
    setFormState({ buttonEnabled: true })

    if (
      formState.firstname <= 0 ||
      formState.email <= 0 ||
      formState.message <= 0
    ) {
      handleServerResponse(false, "Uzupełnij pola przed wysłaniem", form)
      return
    }

    axios({
      method: "post",
      url: "https://www.infofinder.pl/mail.php",
      data: new FormData(form),
    })

    handleServerResponse(
      true,
      "Dziękujemy za kontakt! Odezwiemy się tak szybko, jak to możliwe.",
      form
    )
  }

  const onVerify = event => {
    setFormState({ buttonEnabled: true })
  }

  const onExpire = event => {
    setFormState({ buttonEnabled: false })
  }

  const onNameChange = event => {
    setFormState({ firstname: event.target.value })
  }

  const onMessageChange = event => {
    setFormState({ message: event.target.value })
  }

  const onEmailChange = event => {
    setFormState({ email: event.target.value })
  }

  return (
    <ContactFormWrapper>
      <StyledForm noValidate onSubmit={handleOnSubmit}>
        <label>Nazwa Placówki</label>
        <StyledField
          required
          placeholder="np. Szpital"
          type="text"
          name="placeName"
        />
        <label>Imię i nazwisko</label>
        <StyledField
          value={formState.firstname}
          onChange={onNameChange}
          required
          placeholder="np. Jan Kowalski"
          type="text"
          name="name"
        />
        <label>Adres email</label>
        <StyledField
          value={formState.email}
          onChange={onEmailChange}
          required
          placeholder="np. jan@poczta.pl"
          type="email"
          name="email"
        />
        <label>Stanowisko</label>
        <StyledField
          placeholder="np. Dyrektor szpitala"
          type="text"
          name="position"
        />
        <label>Temat</label>
        <StyledField
          placeholder="np. Zaptanie ofertowe"
          type="text"
          name="topic"
        />
        <label>Twoja wiadomość:</label>
        <textarea
          value={formState.message}
          onChange={onMessageChange}
          name="message"
        ></textarea>
        <ReaptchaMargin />
        <Reaptcha
          sitekey="6LfwMtAUAAAAAG4dF3Gf9LiLqIHyJeW3aATPt033"
          onVerify={onVerify}
          onExpire={onExpire}
        />
        <StyledSubmit
          type="submit"
          value="Send"
          disabled={!formState.buttonEnabled}
        >
          Wyślij
        </StyledSubmit>
        {serverState.status && (
          <InformationParagraph
            color={!serverState.status.ok ? "red" : "black"}
          >
            {serverState.status.msg}
          </InformationParagraph>
        )}
      </StyledForm>
    </ContactFormWrapper>
  )
}

export default ContactForm
