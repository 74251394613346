import React from "react"

import styled from 'styled-components';
import theme from "../../../styles/theme";

import ContactForm from "./ContactForm";
import Map from "./Map";

const StyledWrapper = styled.div`
    margin-top: 100px;
    width: 100%;
    min-height: 50px;
    background: ${theme.colors.contact};

    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: flex-start;
    justify-content: space-between;

    
    @media(min-width: 992px)
    {
        flex-direction: row;
    }
`;

const Divider = styled.div`
    width: 2vw;
    height: 100%;
    background-color: transparent;
`;

const ContactWrapper = () => (
    <StyledWrapper>
        <ContactForm />
        <Divider />
        <Map />
    </StyledWrapper>
)

export default ContactWrapper
