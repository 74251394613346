import React from "react"

const Map = () => (
    <div>
        <h2>InfoFinder<br/> 
            Sp. z o. o.
        </h2>
        <div>
            Wita Stwosza 2
            40-036 Katowice<br/>
            NIP: 8652549704<br/><br/>
        </div>
        <div>
            biuro@infofinder.pl<br/><br/>
        </div>
        <div>
            Godziny otwarcia:
            od pon do pt w godz. 9 – 17
        </div>
    </div>
)

export default Map
