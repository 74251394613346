import React from "react"


import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero";
import ContactWrapper from "../components/Contact/Sections/ContactWrapper"

import { setConfig, cold } from 'react-hot-loader';
const ContactPage = () => {
  setConfig({
    onComponentCreate: (type, name) =>
      (String(type).indexOf('useState') > 0 || String(type).indexOf('useEffect') > 0) && cold(type),
  })

  return(
    <Layout>
      <SEO title="Kontakt" />
      <Hero title="Kontakt" />
      <ContactWrapper />
    </Layout>
  );
}

export default ContactPage
